import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import {Link} from 'gatsby'

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Нажаль такої сторінки не існує" />
    <h1>404: Нажаль, такої сторінки немає</h1>
    <p>&#39;такого сторінки немає.. нажаль.</p>
    <br/>
    <Link to="/">Назад на Головну</Link>
  </Layout>
)

export default NotFoundPage
